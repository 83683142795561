@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

/* Reset default styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Base styles */
body, button {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #333;
  line-height: 1.6;
}

/* Input styles */
input {
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 8px;
  font-size: 18px;
  outline: none;
  font-family: 'Open Sans', sans-serif;
}

input:focus {
  border-color: #d3d3d3;
  box-shadow: 0 0 5px rgba(211, 211, 211, 0.5);
  border-width: 2px;
}

/* Select focus */
select:focus {
  border: 1px solid #e2e8f0 !important;
  outline: none !important;
}

/* Navigation Link Styling */
.nav-item {
  font-size: 16px;
  font-weight: 600;
  color: #38a169 !important;
  text-decoration: none !important;
  padding: 10px 15px;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  position: relative;
  font-family: 'Open Sans', sans-serif;
}

/* Shared Styles */

/* Container */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: #f8fafc;
}

/* Content Wrapper */
.content-wrapper {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 2rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Hero Section */
.hero-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 0;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  gap: 2rem;
}

@media (max-width: 768px) {
  .hero-container {
    flex-direction: column;
    padding: 1rem 0;
  }
}

.hero-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

@media (max-width: 768px) {
  .hero-image-container {
    width: 100%;
    margin-bottom: 1.5rem;
  }
}

.image-wrapper {
  width: 100%;
  max-width: 500px;
  height: 400px;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .image-wrapper {
    height: 300px;
    max-width: 100%;
  }
}

.hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  padding-left: 2rem;
}

@media (max-width: 768px) {
  .hero-text-container {
    width: 100%;
    padding-left: 0;
    align-items: center;
    text-align: center;
  }
}

.hero-title {
  font-family: 'MouseMemoirs', sans-serif;
  font-size: 3rem;
  font-weight: 700;
  color: #1e3a8a;
  margin-bottom: 1rem;
  line-height: 1.2;
}

@media (max-width: 768px) {
  .hero-title {
    font-size: 2rem;
  }
}

.hero-subtitle {
  font-size: 1.25rem;
  color: #4b5563;
  margin-bottom: 1.5rem;
  max-width: 500px;
}

.button-blue {
  background-color: #f97316;
  padding: 1rem 2.5rem;
  border-radius: 50px;
  border: none;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 220px;
}

.button-blue:hover,
.button-blue.button-blue-hover {
  background-color: #ea580c;
  transform: scale(1.05);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

@media (max-width: 768px) {
  .button-blue {
    width: 90%;
    padding: 0.875rem 2rem;
  }
}

/* Divider */
.divider {
  height: 1px;
  background-color: #e5e7eb;
  margin: 3rem 0;
  width: 100%;
  max-width: 1280px;
}

/* Section Container */
.section-container {
  padding: 3rem 0;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}

/* Section Row */
.section-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
}

.section-row-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.section-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-icon-circle {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: linear-gradient(135deg, #f97316, #ea580c);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
}

.section-icon {
  font-size: 3rem;
  color: #fff;
}

.section-text-container {
  flex: 1;
}

@media (max-width: 768px) {
  .section-text-container {
    text-align: center;
  }
}

.section-title {
  font-family: 'MouseMemoirs', sans-serif;
  font-size: 2.25rem;
  font-weight: 700;
  color: #1e3a8a;
  margin-bottom: 0.75rem;
}

@media (max-width: 768px) {
  .section-title {
    font-size: 1.75rem;
  }
}

.section-text {
  font-size: 1rem;
  color: #4b5563;
  line-height: 1.5;
  max-width: 600px;
}

/* Card */
.card {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  margin-bottom: 1rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
}

.card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
}

.step-number {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #1e3a8a;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
}

.step-number-text {
  color: #fff;
  font-size: 1.25rem;
  font-weight: 700;
}

.step-text {
  font-size: 1rem;
  color: #4b5563;
  line-height: 1.5;
  flex: 1;
}

.step-text strong {
  color: #1e3a8a;
}

/* CTA Section */
.cta-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 3rem 4rem;
  gap: 2rem;
  background: linear-gradient(135deg, #1e3a8a, #3b82f6);
  border-radius: 16px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .cta-container {
    flex-direction: column;
    padding: 2rem;
  }
}

.cta-text-container {
  flex: 2;
}

@media (max-width: 768px) {
  .cta-text-container {
    text-align: center;
  }
}

.cta-title {
  font-family: 'MouseMemoirs', sans-serif;
  font-size: 2.5rem;
  font-weight: 700;
  color: #fff;
  padding: 0.5rem 0;
  line-height: 1.2;
}

@media (max-width: 768px) {
  .cta-title {
    font-size: 1.75rem;
  }
}

.cta-subtitle {
  font-size: 1rem;
  color: #dbeafe;
  line-height: 1.5;
  margin: 0.5rem 0;
}

.cta-button {
  background-color: #fff;
  padding: 1rem 2.5rem;
  border-radius: 50px;
  border: none;
  color: #1e3a8a;
  font-size: 1.125rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 220px;
}

.cta-button:hover,
.cta-button.cta-button-hover {
  background-color: #f3f4f6;
  transform: scale(1.05);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

@media (max-width: 768px) {
  .cta-button {
    width: 90%;
    padding: 0.875rem 2rem;
  }
}

/* Footer Divider */
.footer-divider {
  height: 1px;
  background-color: #e5e7eb;
  margin: 3rem 0;
  width: 100%;
  max-width: 1280px;
}

/* Footer */
.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    gap: 1.5rem;
  }
}

.footer-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
}

@media (max-width: 768px) {
  .footer-left {
    flex-direction: column;
    margin-bottom: 1.5rem;
  }
}

.footer-logo {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.social-icons {
  display: flex;
  gap: 1rem;
}

.social-link {
  color: #6b7280;
  text-decoration: none;
  transition: color 0.3s ease;
}

.social-link:hover {
  color: #1e3a8a;
}

.social-icon {
  width: 24px;
  height: 24px;
  stroke-width: 2;
}

.footer-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
}

@media (max-width: 768px) {
  .footer-right {
    flex-direction: column;
    gap: 0.75rem;
  }
}

.footer-link-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.footer-link {
  font-size: 1rem;
  color: #6b7280;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-link:hover {
  color: #1e3a8a;
}

/* Bottom Bar */
.bottom-bar {
  background-color: #f3f4f6;
  padding: 1rem 0;
  text-align: center;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  border-top: 1px solid #e5e7eb;
}

.dog-fact {
  font-size: 0.875rem;
  color: #6b7280;
  font-style: italic;
  margin: 0;
}

/* SkillsScreen Modern Light Theme Styles */
.skills-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: linear-gradient(135deg, #f8fafc 0%, #e0e7ff 100%);
  position: relative;
  overflow: hidden;
}

.skills-container .hero-section {
  padding: 4rem 1rem;
  text-align: center;
  position: relative;
  background: linear-gradient(180deg, rgba(248, 250, 252, 0.9), rgba(224, 231, 255, 0.8));
  border-bottom: 1px solid #d1d5db;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}

.skills-container .hero-title {
  font-family: 'MouseMemoirs', sans-serif;
  font-size: 2.5rem;
  font-weight: 700;
  color: #1e3a8a;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  text-shadow: 0 2px 4px rgba(30, 58, 138, 0.1);
}

@media (max-width: 768px) {
  .skills-container .hero-title {
    font-size: 1.75rem;
  }
}

.skills-container .hero-subtitle {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.125rem;
  color: #4b5563;
  max-width: 90%;
  margin: 0 auto;
}

.category-container {
  margin: 2.5rem 0;
  text-align: center;
  width: 100%;
  max-width: 1280px;
}

.category-title {
  font-family: 'MouseMemoirs', sans-serif;
  font-size: 2rem;
  font-weight: 700;
  color: #f97316;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  text-shadow: 0 2px 4px rgba(249, 115, 22, 0.1);
}

.web-category-layout {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  justify-content: center;
  width: 100%;
  max-width: 1280px;
}

.skill-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 250px;
  margin: 0 auto;
}

.skill-circle {
  width: 100%;
  max-width: 250px;
  height: 250px;
  border-radius: 50%;
  background: linear-gradient(135deg, #ffffff, #e0e7ff);
  border: 2px solid #1e3a8a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.skill-circle-hover {
  transform: scale(1.05);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
  border-color: #f97316;
}

.skill-circle-unlocked {
  border: 2px solid #38a169;
  background: linear-gradient(135deg, #ffffff, #d1fae5);
  box-shadow: 0 6px 18px rgba(56, 161, 105, 0.2);
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% { box-shadow: 0 4px 12px rgba(56, 161, 105, 0.2); }
  50% { box-shadow: 0 8px 24px rgba(56, 161, 105, 0.3); }
  100% { box-shadow: 0 4px 12px rgba(56, 161, 105, 0.2); }
}

.skill-icon {
  font-size: 4rem;
  color: #1e3a8a;
  margin-bottom: 0.75rem;
}

.skill-label {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  color: #1e3a8a;
  text-align: center;
  line-height: 1.2;
  max-width: 90%;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.skill-desc {
  font-family: 'Open Sans', sans-serif;
  font-size: 0.875rem;
  color: #6b7280;
  margin-top: 0.75rem;
  max-width: 100%;
}

.rating-row {
  margin-top: 0.5rem;
  text-align: center;
}

.star-filled {
  color: #f97316;
  font-size: 1rem;
}

.star-empty {
  color: #d1d5db;
  font-size: 1rem;
}

.loading {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.25rem;
  text-align: center;
  margin-top: 2rem;
  color: #1e3a8a;
}

.error {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.25rem;
  color: #ef4444;
  text-align: center;
  margin-top: 2rem;
}

.no-skills-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.125rem;
  color: #6b7280;
  text-align: center;
}

.personalized-plan-container {
  margin: 3rem 0;
  text-align: center;
  width: 100%;
  max-width: 1280px;
  position: relative;
}

.personalized-plan-title {
  font-family: 'MouseMemoirs', sans-serif;
  font-size: 2rem;
  font-weight: 700;
  color: #1e3a8a;
  margin-bottom: 1rem;
  text-transform: uppercase;
  text-shadow: 0 2px 4px rgba(30, 58, 138, 0.1);
}

.personalized-plan-subtitle {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  color: #6b7280;
  margin-bottom: 2rem;
}

.plan-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  justify-content: center;
  width: 100%;
  max-width: 1280px;
}

.plan-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 250px;
  margin: 0 auto;
}

.plan-skill-circle {
  width: 100%;
  max-width: 250px;
  height: 250px;
  border-radius: 50%;
  background: linear-gradient(135deg, #ffffff, #d1fae5);
  border: 2px solid #38a169;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.plan-skill-circle-progress {
  border: 2px solid #f97316;
  background: linear-gradient(135deg, #ffffff, #ffedd5);
  box-shadow: 0 6px 18px rgba(249, 115, 22, 0.2);
}

.plan-reason {
  font-family: 'Open Sans', sans-serif;
  font-size: 0.875rem;
  color: #6b7280;
  margin-top: 0.75rem;
}

.plan-progress {
  margin-top: 0.5rem;
  text-align: center;
}

.section-divider {
  border: none;
  border-top: 1px dashed #d1d5db;
  width: 100%;
  max-width: 1280px;
  margin: 1.5rem auto;
}

.paw-print {
  position: absolute;
  font-size: 1.5rem;
  color: #1e3a8a;
  opacity: 0.2;
}

.paw-print.top-left {
  top: 10px;
  left: 20px;
}

.paw-print.bottom-right {
  bottom: 10px;
  right: 20px;
}

.no-plan-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.125rem;
  color: #6b7280;
  text-align: center;
}

/* OnboardingScreen Modern Light Theme Styles */
.onboarding-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #f8fafc 0%, #e0e7ff 100%);
  padding: 1rem;
}

.onboarding-content-wrapper {
  width: 100%;
  max-width: 600px;
  padding: 2rem;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 16px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .onboarding-content-wrapper {
    padding: 1rem;
    max-width: 100%;
  }
}

.onboarding-logo {
  width: 150px;
  height: auto;
  display: block;
  margin: 0 auto 1.5rem;
  object-fit: contain;
  animation: fadeBounceIn 1s ease-out;
}

@media (max-width: 768px) {
  .onboarding-logo {
    width: 100px;
  }
}

@keyframes fadeBounceIn {
  0% {
    opacity: 0;
    transform: scale(0.8) translateY(-20px);
  }
  60% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

.onboarding-progress-bar {
  width: 100%;
  height: 10px;
  background-color: #e2e8f0;
  margin-bottom: 1.5rem;
  border-radius: 5px;
  overflow: hidden;
}

.onboarding-progress-fill {
  height: 100%;
  background-color: #38a169;
  transition: width 0.3s ease;
}

.onboarding-header {
  font-family: 'MouseMemoirs', sans-serif;
  font-size: 2rem;
  font-weight: 700;
  color: #1e3a8a;
  margin-bottom: 1.5rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  text-shadow: 0 2px 4px rgba(30, 58, 138, 0.1);
}

@media (max-width: 768px) {
  .onboarding-header {
    font-size: 1.5rem;
  }
}

.onboarding-input {
  width: 100%;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1.125rem;
  box-sizing: border-box;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.onboarding-input:focus {
  border-color: #1e3a8a;
  box-shadow: 0 0 8px rgba(30, 58, 138, 0.2);
}

.onboarding-password-container {
  position: relative;
  width: 100%;
  margin-bottom: 1.5rem;
}

.onboarding-password-input {
  width: 100%;
  padding: 1rem 3rem 1rem 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1.125rem;
  box-sizing: border-box;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.onboarding-password-input:focus {
  border-color: #1e3a8a;
  box-shadow: 0 0 8px rgba(30, 58, 138, 0.2);
}

.onboarding-eye-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  font-size: 1.5rem;
  color: #6b7280;
  transition: color 0.3s ease;
}

.onboarding-eye-icon:hover {
  color: #1e3a8a;
}

.onboarding-radio-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  gap: 0.75rem;
}

.onboarding-radio-container {
  display: flex;
  align-items: center;
  padding: 0.75rem;
  cursor: pointer;
  background: none;
  border: none;
  width: 100%;
  transition: background-color 0.3s ease;
}

.onboarding-radio-container:hover {
  background-color: rgba(224, 231, 255, 0.5);
}

.onboarding-radio-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ccc;
  margin-right: 0.75rem;
  transition: all 0.3s ease;
}

.onboarding-radio-square {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 2px solid #ccc;
  margin-right: 0.75rem;
  transition: all 0.3s ease;
}

.onboarding-radio-selected {
  background-color: #1e3a8a;
  border-color: #1e3a8a;
}

.onboarding-radio-label {
  font-size: 1.125rem;
  font-weight: 600;
  color: #444;
}

.onboarding-image-picker {
  background: linear-gradient(135deg, #ffffff, #e0e7ff);
  padding: 2.5rem;
  border-radius: 12px;
  text-align: center;
  margin: 0.75rem 0;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dashed #1e3a8a;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.onboarding-image-picker:hover {
  border-color: #f97316;
  box-shadow: 0 6px 18px rgba(249, 115, 22, 0.1);
}

.onboarding-image-picker-text {
  color: #1e3a8a;
  font-size: 1.125rem;
  font-weight: 600;
}

.onboarding-dog-image-preview {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-top: 0.75rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.onboarding-uploading-text {
  font-size: 1rem;
  text-align: center;
  margin-top: 0.75rem;
  color: #38a169;
}

.onboarding-error {
  color: #ef4444;
  margin-bottom: 1.5rem;
  text-align: center;
  font-size: 1rem;
}

.onboarding-button-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 1.5rem;
  gap: 1rem;
}

.onboarding-back-button {
  padding: 0.75rem 1.5rem;
  background-color: #fff;
  border: 2px solid #d1d5db;
  font-size: 1.125rem;
  font-weight: 600;
  border-radius: 50px;
  width: 150px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  color: #6b7280;
}

.onboarding-back-button:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
  border-color: #1e3a8a;
  color: #1e3a8a;
}

.onboarding-back-button:active {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transform: translateY(1px);
}

.onboarding-next-button {
  padding: 0.75rem 1.5rem;
  background: linear-gradient(135deg, #38a169, #16a34a);
  border: none;
  width: 150px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  opacity: 1;
}

.onboarding-next-button.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.onboarding-next-button:hover:not(.disabled) {
  background: linear-gradient(135deg, #16a34a, #15803d);
  transform: scale(1.05);
  box-shadow: 0 4px 12px rgba(56, 161, 105, 0.3);
}

.onboarding-button-text {
  color: #fff;
  font-size: 1.125rem;
  font-weight: 600;
}

.onboarding-footer-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 9rem;
  margin-bottom: 1.5rem;
  width: 100%;
}

.onboarding-agreement-text {
  font-size: 0.875rem;
  color: #6b7280;
  text-align: center;
  margin-bottom: 1rem;
}

.onboarding-footer-link-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.onboarding-footer-link {
  font-size: 1rem;
  color: #1e3a8a;
  text-decoration: none;
  transition: color 0.3s ease;
}

.onboarding-footer-link:hover {
  color: #f97316;
}

.onboarding-login-link-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.onboarding-login-link {
  font-size: 0.875rem;
  color: #1e3a8a;
  text-decoration: none;
  transition: color 0.3s ease;
}

.onboarding-login-link:hover {
  color: #f97316;
}

/* TrainerScreen Modern Light Theme Styles */
.trainer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #f8fafc 0%, #e0e7ff 100%);
  padding: 2rem 1rem;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}

.trainer-content-wrapper {
  width: 100%;
  max-width: 600px;
  padding: 1.5rem;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 16px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .trainer-content-wrapper {
    padding: 1rem;
    max-width: 100%;
  }
}

.trainer-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.trainer-dialog-box {
  background: linear-gradient(135deg, #ffffff, #e0e7ff);
  padding: 2rem;
  border-radius: 16px;
  max-width: 450px;
  width: 90%;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: relative;
}

@media (max-width: 768px) {
  .trainer-dialog-box {
    padding: 1.5rem;
  }
}

.trainer-dialog-heading {
  font-family: 'MouseMemoirs', sans-serif;
  font-size: 1.75rem;
  font-weight: 700;
  color: #1e3a8a;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  text-shadow: 0 2px 4px rgba(30, 58, 138, 0.1);
}

@media (max-width: 768px) {
  .trainer-dialog-heading {
    font-size: 1.5rem;
  }
}

.trainer-dialog-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  color: #4b5563;
  line-height: 1.5;
}

.trainer-bullet-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0.75rem 0;
  text-align: left;
}

.trainer-bullet-icon {
  margin-right: 0.75rem;
  color: #38a169;
  font-size: 1.125rem;
}

.trainer-dialog-button {
  padding: 0.75rem 1.5rem;
  border-radius: 50px;
  background: linear-gradient(135deg, #38a169, #16a34a);
  border: none;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 1.5rem;
}

.trainer-dialog-button:hover {
  background: linear-gradient(135deg, #16a34a, #15803d);
  transform: scale(1.05);
  box-shadow: 0 4px 12px rgba(56, 161, 105, 0.3);
}

.trainer-close-button {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #e2e8f0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.125rem;
  color: #4b5563;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.trainer-close-button:hover {
  transform: scale(1.1);
}

.trainer-progress-container {
  width: 100%;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.trainer-progress-bar {
  flex: 1;
  height: 12px;
  background-color: #e2e8f0;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.trainer-progress-fill {
  height: 100%;
  background-color: #38a169;
  border-radius: 6px;
  transition: width 0.5s ease;
}

.trainer-progress-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 0.875rem;
  color: #4b5563;
}

.trainer-heading {
  font-family: 'MouseMemoirs', sans-serif;
  font-size: 2rem;
  font-weight: 700;
  color: #1e3a8a;
  text-align: center;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  text-shadow: 0 2px 4px rgba(30, 58, 138, 0.1);
}

@media (max-width: 768px) {
  .trainer-heading {
    font-size: 1.75rem;
  }
}

.trainer-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.125rem;
  color: #4b5563;
  text-align: center;
  margin-bottom: 1.5rem;
  line-height: 1.5;
}

.trainer-image {
  width: 100%;
  max-width: 400px;
  height: auto;
  border-radius: 16px;
  margin: 0 auto 1.5rem auto;
  display: block;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .trainer-image {
    max-width: 300px;
  }
}

.trainer-action-button-container {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

@media (max-width: 768px) {
  .trainer-action-button-container {
    gap: 1rem;
  }
}

.trainer-nav-container {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
}

@media (max-width: 768px) {
  .trainer-nav-container {
    gap: 1rem;
  }
}

.trainer-button {
  padding: 0.75rem 1.5rem;
  border-radius: 50px;
  background-color: #fff;
  border: 2px solid #1e3a8a;
  color: #1e3a8a;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 150px;
}

.trainer-button:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 12px rgba(30, 58, 138, 0.2);
  background-color: #e0e7ff;
}

.trainer-chat-container {
  width: 100%;
  max-width: 600px;
  padding: 1.5rem;
  box-sizing: border-box;
  background-color: #f7fafc;
  border-radius: 16px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .trainer-chat-container {
    padding: 1rem;
    min-height: 300px;
  }
}

.trainer-chat-logo {
  width: 70px !important;
  height: 70px !important;
  margin-top: 10px !important;
  object-fit: contain;
  margin-bottom: 1rem;
  animation: wag 1s ease-in-out infinite;
}

@keyframes wag {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(5deg); }
  75% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
}

.trainer-chat-box {
  flex: 1;
  width: 100%;
  overflow-y: auto;
  margin-top: 1rem;
  padding: 0.75rem;
  box-sizing: border-box;
}

.trainer-user-message {
  text-align: right;
  background-color: #f97316;
  color: #fff;
  padding: 0.75rem;
  border-radius: 12px;
  margin: 0.5rem 0;
  max-width: 100%;
  align-self: flex-end;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
}

.trainer-ai-message {
  text-align: left;
  background-color: #e0e7ff;
  color: #1e3a8a;
  padding: 0.75rem;
  border-radius: 12px;
  margin: 0.5rem 0;
  max-width: 100%;
  align-self: flex-start;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
}

.trainer-input-container {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px 0;
  padding: 5px 10px;
  border: 1px solid #ccc; /* Light grey border */
  border-radius: 20px;
  box-sizing: border-box;
  background: #fff; /* Solid white background, not transparent */
}

.trainer-text-input {
  flex: 1;
  padding: 0.75rem;
  margin-right: 1rem;
  border-radius: 8px;
  border: 1px solid #d1d5db;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  box-sizing: border-box;
  outline: none;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  resize: none;
  height: 50px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.trainer-text-input:focus {
  border-color: #1e3a8a;
  box-shadow: 0 0 8px rgba(30, 58, 138, 0.2);
}

.trainer-send-button {
  padding: 0.75rem;
  border-radius: 50%;
  background-color: #38a169;
  border: none;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  transition: transform 0.3s ease;
}

.trainer-send-button:hover {
  transform: scale(1.1);
}

.trainer-back-button {
  width: 48px; /* Slightly larger for Material Design */
  height: 48px;
  padding: 0;
  background: #fff; /* White background */
  border: 1px solid #ccc; /* Light grey border */
  border-radius: 50%; /* Circular shape */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  transition: background-color 0.2s; /* Smooth hover transition */
}

.trainer-back-button:hover {
  background: #f5f5f5; /* Light grey hover effect, Material Design style */
}

.trainer-back-button svg {
  fill: #999; /* Light grey arrow */
}

.trainer-back-button:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
  border-color: #1e3a8a;
  color: #1e3a8a;
}

.trainer-loading-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.125rem;
  color: #1e3a8a;
  text-align: center;
  margin-top: 1.5rem;
}

.trainer-error-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  color: #ef4444;
  text-align: center;
  margin-top: 1.5rem;
}

/* SubscriptionScreen Modern Light Theme Styles */
.subscription-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #f8fafc 0%, #e0e7ff 100%);
  padding: 2rem 1rem;
  width: 100%;
  box-sizing: border-box;
}

.subscription-content-wrapper {
  width: 100%;
  max-width: 900px;
  text-align: center;
  padding: 0;
}

@media (max-width: 768px) {
  .subscription-content-wrapper {
    max-width: 100%;
    padding: 0.5rem;
  }
}

.subscription-logo {
  width: 100px;
  height: 100px;
  margin-bottom: 1.5rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  animation: fadeBounceIn 1s ease-out;
}

@media (max-width: 768px) {
  .subscription-logo {
    width: 80px;
    height: 80px;
  }
}

.subscription-main-heading {
  font-family: 'MouseMemoirs', sans-serif;
  font-size: 2.5rem;
  font-weight: 700;
  color: #1e3a8a;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  text-shadow: 0 2px 4px rgba(30, 58, 138, 0.1);
}

@media (max-width: 768px) {
  .subscription-main-heading {
    font-size: 2rem;
  }
}

.subscription-info-card {
  background-color: #f7fafc;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.5rem;
}

@media (max-width: 768px) {
  .subscription-info-card {
    padding: 1rem;
  }
}

.subscription-card-title {
  font-family: 'MouseMemoirs', sans-serif;
  font-size: 2rem;
  font-weight: 700;
  color: #1e3a8a;
  margin-bottom: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

@media (max-width: 768px) {
  .subscription-card-title {
    font-size: 1.5rem;
  }
}

.subscription-card-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  color: #4b5563;
}

@media (max-width: 768px) {
  .subscription-card-text {
    font-size: 0.875rem;
  }
}

.subscription-divider {
  height: 2px;
  background-color: #e2e8f0;
  margin: 1.5rem 0;
  width: 100%;
}

.subscription-cost-benefits-container {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

@media (max-width: 768px) {
  .subscription-cost-benefits-container {
    flex-direction: column;
    gap: 1rem;
  }
}

.subscription-cost-card,
.subscription-benefits-card {
  background-color: #f7fafc;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: left;
  width: 50%;
}

@media (max-width: 768px) {
  .subscription-cost-card,
  .subscription-benefits-card {
    width: 100%;
    padding: 1rem;
  }
}

.subscription-card-text-left {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  color: #4b5563;
  margin-bottom: 0.75rem;
}

@media (max-width: 768px) {
  .subscription-card-text-left {
    font-size: 0.875rem;
  }
}

.subscription-plan-header {
  font-family: 'MouseMemoirs', sans-serif;
  font-size: 2rem;
  font-weight: 700;
  color: #1e3a8a;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  text-shadow: 0 2px 4px rgba(30, 58, 138, 0.1);
}

@media (max-width: 768px) {
  .subscription-plan-header {
    font-size: 1.75rem;
  }
}

.subscription-plan-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  gap: 1rem;
}

@media (max-width: 768px) {
  .subscription-plan-container {
    flex-direction: column;
    gap: 1rem;
  }
}

.subscription-plan-left,
.subscription-plan-right {
  width: 45%;
}

@media (max-width: 768px) {
  .subscription-plan-left,
  .subscription-plan-right {
    width: 100%;
  }
}

.subscription-picker-container {
  position: relative;
  width: 100%;
}

.subscription-picker {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  background-color: #fff;
  box-sizing: border-box;
  outline: none;
  appearance: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

@media (max-width: 768px) {
  .subscription-picker {
    margin-top: 0;
    padding: 0.5rem;
  }
}

.subscription-picker:focus {
  border-color: #1e3a8a;
  box-shadow: 0 0 8px rgba(30, 58, 138, 0.2);
}

.subscription-picker-caret {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #444;
  pointer-events: none;
}

.subscription-coupon-input {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  box-sizing: border-box;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.subscription-coupon-input:focus {
  border-color: #1e3a8a;
  box-shadow: 0 0 8px rgba(30, 58, 138, 0.2);
}

.subscription-checkbox-container {
  display: flex;
  align-items: center;
  margin-top: 0.75rem;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0.5rem;
  transition: background-color 0.3s ease;
}

.subscription-checkbox-container:hover {
  background-color: rgba(224, 231, 255, 0.5);
}

.subscription-checkbox-square {
  width: 20px;
  height: 20px;
  border: 2px solid #1e3a8a;
  background-color: transparent;
  margin-right: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.subscription-checkbox-checked {
  background-color: #1e3a8a;
  border-color: #1e3a8a;
}

.subscription-checkbox-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  color: #444;
}

.subscription-plan-details-card {
  padding: 1.5rem;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .subscription-plan-details-card {
    padding: 1rem;
  }
}

.subscription-plan-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  color: #1e3a8a;
  margin-bottom: 0.75rem;
}

@media (max-width: 768px) {
  .subscription-plan-title {
    font-size: 1.25rem;
  }
}

.subscription-plan-price {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.125rem;
  color: #38a169;
  margin-bottom: 0.75rem;
}

@media (max-width: 768px) {
  .subscription-plan-price {
    font-size: 1rem;
  }
}

.subscription-plan-original {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  text-decoration: line-through;
  color: #999;
}

.subscription-plan-per-day {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  color: #4b5563;
}

.subscription-total-price-card {
  background-color: #f7fafc;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.5rem;
}

@media (max-width: 768px) {
  .subscription-total-price-card {
    padding: 1rem;
  }
}

.subscription-total-price-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  color: #1e3a8a;
}

@media (max-width: 768px) {
  .subscription-total-price-text {
    font-size: 1.125rem;
  }
}

.subscription-donation-note {
  font-family: 'Open Sans', sans-serif;
  font-size: 0.875rem;
  color: #6b7280;
  margin-top: 0.75rem;
}

.subscription-disclaimer-text,
.subscription-recurring-disclaimer {
  font-family: 'Open Sans', sans-serif;
  font-size: 0.875rem;
  padding-top: 0.75rem;
  color: #6b7280;
}

.subscription-start-button {
  background: linear-gradient(135deg, #38a169, #16a34a);
  padding: 0.75rem 1.5rem;
  border-radius: 50px;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.125rem;
  font-weight: 600;
  cursor: pointer;
  border: none;
  width: auto;
  transition: all 0.3s ease;
}

.subscription-start-button:hover {
  background: linear-gradient(135deg, #16a34a, #15803d);
  transform: scale(1.05);
  box-shadow: 0 4px 12px rgba(56, 161, 105, 0.3);
}

.subscription-start-button.disabled {
  opacity: 0.7;
  pointer-events: none;
}

@media (max-width: 768px) {
  .subscription-start-button {
    width: 100%;
    padding: 0.75rem;
  }
}

.subscription-countdown-bar {
  background-color: #ef4444;
  padding: 1rem;
  border-radius: 8px;
  color: #fff;
  margin-bottom: 1.5rem;
}

.subscription-countdown-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
}

.subscription-free-skill-link {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  color: #1e3a8a;
  text-decoration: underline;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  padding-bottom: 1rem;
  transition: color 0.3s ease;
}

.subscription-free-skill-link:hover {
  color: #f97316;
}

.subscription-error-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  color: #ef4444;
  margin-bottom: 1.5rem;
  text-align: center;
}

.subscription-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.subscription-popup-content {
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 400px;
  max-width: 80%;
}

.subscription-popup-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  color: #333;
  margin-bottom: 1.5rem;
}

.subscription-popup-button-container {
  display: flex;
  justify-content: space-around;
  gap: 0.75rem;
}

.subscription-popup-button {
  background: linear-gradient(135deg, #38a169, #16a34a);
  padding: 0.75rem 1.5rem;
  border-radius: 50px;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  border: none;
  transition: all 0.3s ease;
}

.subscription-popup-button:hover {
  background: linear-gradient(135deg, #16a34a, #15803d);
  transform: scale(1.05);
  box-shadow: 0 4px 12px rgba(56, 161, 105, 0.3);
}

/* EditProfileScreen Modern Light Theme Styles */
.edit-profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #f8fafc 0%, #e0e7ff 100%);
  padding: 2rem 1rem;
  width: 100%;
  box-sizing: border-box;
}

.edit-profile-content-wrapper {
  width: 100%;
  max-width: 600px;
  padding: 1.5rem;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .edit-profile-content-wrapper {
    padding: 1rem;
    max-width: 100%;
  }
}

.edit-profile-section {
  background-color: #f7fafc;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.5rem;
}

@media (max-width: 768px) {
  .edit-profile-section {
    padding: 1rem;
  }
}

.edit-profile-title {
  font-family: 'MouseMemoirs', sans-serif;
  font-size: 2rem;
  font-weight: 700;
  color: #1e3a8a;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  text-shadow: 0 2px 4px rgba(30, 58, 138, 0.1);
}

@media (max-width: 768px) {
  .edit-profile-title {
    font-size: 1.5rem;
  }
}

.edit-profile-input {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 0.75rem;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  box-sizing: border-box;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.edit-profile-input:focus {
  border-color: #1e3a8a;
  box-shadow: 0 0 8px rgba(30, 58, 138, 0.2);
}

.edit-profile-select {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 0.75rem;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  box-sizing: border-box;
  outline: none;
  appearance: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.edit-profile-select:focus {
  border-color: #1e3a8a;
  box-shadow: 0 0 8px rgba(30, 58, 138, 0.2);
}

.edit-profile-multi-select-container {
  margin-bottom: 0.75rem;
}

.edit-profile-multi-option {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
}

.edit-profile-checkbox {
  margin-right: 0.75rem;
}

.edit-profile-button {
  background: linear-gradient(135deg, #38a169, #16a34a);
  padding: 0.75rem 1.5rem;
  border-radius: 50px;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.125rem;
  font-weight: 600;
  cursor: pointer;
  border: none;
  width: 100%;
  transition: all 0.3s ease;
  margin-top: 1.5rem;
}

.edit-profile-button:hover:not(.disabled) {
  background: linear-gradient(135deg, #16a34a, #15803d);
  transform: scale(1.05);
  box-shadow: 0 4px 12px rgba(56, 161, 105, 0.3);
}

.edit-profile-button.disabled {
  opacity: 0.7;
  pointer-events: none;
}

.edit-profile-loading-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.125rem;
  color: #6b7280;
  text-align: center;
}

.edit-profile-error-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  color: #ef4444;
  text-align: center;
  margin-bottom: 0.75rem;
}

.edit-profile-success-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  color: #38a169;
  text-align: center;
  margin-bottom: 0.75rem;
}

.edit-profile-image-preview {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 0.75rem;
  object-fit: cover;
  display: block;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.edit-profile-image-picker {
  background: linear-gradient(135deg, #ffffff, #e0e7ff);
  padding: 1.5rem;
  border-radius: 12px;
  text-align: center;
  margin-bottom: 0.75rem;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  border: 2px dashed #1e3a8a;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.edit-profile-image-picker:hover {
  border-color: #f97316;
  box-shadow: 0 6px 18px rgba(249, 115, 22, 0.1);
}

.edit-profile-image-picker-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  color: #1e3a8a;
}

.trainer-prompt-container {
  margin: 10px 0;
  text-align: center;
}

.trainer-prompt-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.trainer-prompt-button {
  background-color: #f5e8d3;
  border: 1px solid #d4a373;
  border-radius: 20px;
  padding: 8px 15px;
  font-size: 12px;
  color: #5b3e2c;
  cursor: pointer;
  transition: background-color 0.2s;
}

.trainer-prompt-button:hover {
  background-color: #e6d7b9;
}

/* Chat container styling (for both views) */
.trainer-chat-container,
.trainer-no-image-chat-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 51rem;
  margin: 20px auto;
  height: 100vh;
  background: #fff;
  border-radius: 20px;
  box-sizing: border-box;
  position: relative;
}

/* Sticky header for logo and back button */
.trainer-chat-header-sticky {
  position: sticky;
  top: 0;
  padding: 10px 20px;
  background: #fff;
  z-index: 10;
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  justify-content: center;
}

/* Back button (absolute left) */
.trainer-chat-header-sticky .trainer-back-button {
  position: absolute;
  left: 20px;
  width: 48px;
  height: 48px;
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11;
}

/* Center the logo */
.trainer-chat-header-sticky .trainer-chat-logo {
  max-width: 150px;
  margin: 0;
}

/* Scrollable section (greeting, prompts, chat) */
.trainer-chat-scrollable {
  flex-grow: 1;
  overflow-y: auto;
  width: 100%;
}

/* Chat box (scrollable middle section) */
.trainer-chat-box {
  width: 100%;
  overflow-y: auto;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 150px; /* Increased to accommodate action bar */
}

/* User messages (right-aligned, full width) */
.trainer-user-message {
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
  width: 100%;
}

.trainer-user-message p {
  padding: 10px 15px;
  border-radius: 15px;
  width: 100%;
  word-wrap: break-word;
  text-align: right;
}

/* AI messages (left-aligned, full width) */
.trainer-ai-message {
  display: flex;
  justify-content: flex-start;
  margin: 10px 0;
  width: 100%;
}

.trainer-ai-message p {
  padding: 10px 15px;
  border-radius: 15px;
  width: 100%;
  word-wrap: break-word;
  text-align: left;
}

/* Footer (fixed on mobile, static on web) */
.trainer-chat-footer,
.trainer-no-image-chat-footer {
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}

/* Input container with send button inside */
.trainer-input-container {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px 0;
  padding: 5px 10px;
  border: 1px solid #ccc; /* Light grey border */
  border-radius: 20px;
  box-sizing: border-box;
  background: #fff; /* Solid white background, not transparent */
}

/* Textarea styling */
.trainer-text-input {
  flex-grow: 1;
  padding: 10px;
  border: none;
  background: transparent;
  outline: none;
  resize: none;
  min-height: 40px;
  box-sizing: border-box;
}

.trainer-text-input:focus {
  box-shadow: none; /* Remove shadow on focus */
}

/* Action bar for no-image chat view */
.trainer-action-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px 0;
}

/* Left side of action bar (clicker and complete buttons) */
.trainer-action-bar-left {
  display: flex;
  gap: 10px;
}

/* Generic action button styling */
.trainer-action-button {
  width: 40px;
  height: 40px;
  padding: 0;
  border: 1px solid #ccc; /* Light grey border */
  border-radius: 50%; /* Circular shape */
  background: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5b3e2c;
}

/* Specific styling for clicker button */
.trainer-clicker-button svg {
  fill: #5b3e2c;
}

/* Specific styling for complete button */
.trainer-complete-button svg {
  fill: #5b3e2c;
}

/* Send button (right side of action bar) */
.trainer-action-bar .trainer-send-button {
  width: 40px;
  height: 40px;
  padding: 0;
  border: 1px solid #ccc; /* Light grey border */
  border-radius: 50%; /* Circular shape */
  background: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5b3e2c;
}

/* Web: Integrate input into chat card */
@media (min-width: 769px) {
  .trainer-content-wrapper .trainer-steps-container {
    display: block;
  }

  .trainer-content-wrapper:has(.trainer-chat-container) .trainer-steps-container,
  .trainer-content-wrapper:has(.trainer-no-image-chat-container) .trainer-steps-container {
    display: none;
  }

  .trainer-chat-container,
  .trainer-no-image-chat-container {
    box-shadow: none; /* Remove shadow when chat is active */
  }

  .trainer-chat-footer,
  .trainer-no-image-chat-footer {
    position: static; /* Inside chat card, not fixed */
  }
}

/* Mobile full-screen chat */
@media (max-width: 768px) {
  .trainer-chat-container,
  .trainer-no-image-chat-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding: 0;
    margin: 0;
    border-radius: 0; /* No rounded corners on mobile */
    z-index: 1000;
  }

  .trainer-chat-header-sticky {
    padding: 10px;
  }

  .trainer-chat-scrollable {
    margin-top: 0; /* No extra margin since header is sticky */
  }

  .trainer-chat-box {
    margin-bottom: 150px; /* Space for footer */
  }

  .trainer-chat-footer,
  .trainer-no-image-chat-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    z-index: 20; /* Above chat box */
  }
}

/* Steps container (non-chat view) */
.trainer-steps-container {
  display: block;
  background: #fff;
  border-radius: 20px;
  max-width: 51rem;
  margin: 20px auto;
}

/* Ensure the steps container is hidden when chat is active */
.trainer-content-wrapper .trainer-steps-container {
  display: block;
}

.trainer-content-wrapper:has(.trainer-chat-container) .trainer-steps-container,
.trainer-content-wrapper:has(.trainer-no-image-chat-container) .trainer-steps-container {
  display: none;
}

/* LoginScreen Modern Light Theme Styles */
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #f8fafc 0%, #e0e7ff 100%);
  padding: 2rem 1rem;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}

.login-logo-container {
  margin-bottom: 2rem;
}

.login-logo-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.login-logo {
  width: 120px;
  height: 120px;
  object-fit: contain;
  animation: fadeBounceIn 1s ease-out;
}

@media (max-width: 768px) {
  .login-logo {
    width: 100px;
    height: 100px;
  }
}

.login-header {
  font-family: 'MouseMemoirs', sans-serif;
  font-size: 2.5rem;
  font-weight: 700;
  color: #1e3a8a;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  text-shadow: 0 2px 4px rgba(30, 58, 138, 0.1);
}

@media (max-width: 768px) {
  .login-header {
    font-size: 2rem;
  }
}

.login-form-container {
  width: 100%;
  max-width: 400px;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 16px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .login-form-container {
    padding: 1.5rem;
    max-width: 90%;
  }
}

.login-input {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  box-sizing: border-box;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.login-input:focus {
  border-color: #1e3a8a;
  box-shadow: 0 0 8px rgba(30, 58, 138, 0.2);
}

.login-password-container {
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
}

.login-password-input {
  width: 100%;
  padding: 0.75rem 2.5rem 0.75rem 0.75rem;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  box-sizing: border-box;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.login-password-input:focus {
  border-color: #1e3a8a;
  box-shadow: 0 0 8px rgba(30, 58, 138, 0.2);
}

.login-eye-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  font-size: 1.25rem;
  color: #6b7280;
  transition: color 0.3s ease;
}

.login-eye-icon:hover {
  color: #1e3a8a;
}

.login-error {
  font-family: 'Open Sans', sans-serif;
  font-size: 0.875rem;
  color: #ef4444;
  margin-bottom: 1rem;
  text-align: center;
}

.login-button {
  background: linear-gradient(135deg, #38a169, #16a34a);
  padding: 0.75rem 1.5rem;
  border-radius: 50px;
  border: none;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.125rem;
  font-weight: 600;
  cursor: pointer;
  width: 100%;
  transition: all 0.3s ease;
}

.login-button:hover {
  background: linear-gradient(135deg, #16a34a, #15803d);
  transform: scale(1.05);
  box-shadow: 0 4px 12px rgba(56, 161, 105, 0.3);
}

.login-link-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.login-forgot-password {
  font-family: 'Open Sans', sans-serif;
  font-size: 0.875rem;
  color: #1e3a8a;
  text-decoration: underline;
  margin: 1rem 0;
  transition: color 0.3s ease;
}

.login-forgot-password:hover {
  color: #f97316;
}

.login-create-account {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  color: #1e3a8a;
  text-decoration: underline;
  margin-top: 1.5rem;
  transition: color 0.3s ease;
}

.login-create-account:hover {
  color: #f97316;
}

.login-loading {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.125rem;
  color: #1e3a8a;
  text-align: center;
  margin-top: 2rem;
}
/* Next Tip Button Styling (Inline with Action Buttons) */
.trainer-next-tip-button {
  background-color: #f5e8d3;
  border: 1px solid #d4a373;
  border-radius: 20px;
  padding: 8px 15px;
  font-size: 12px;
  color: #5b3e2c;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.2s;
  font-family: 'Open Sans', sans-serif;
  height: 40px; /* Match height of action buttons */
  display: flex;
  align-items: center;
  justify-content: center;
}

.trainer-next-tip-button:hover:not(:disabled) {
  background-color: #e6d7b9;
  transform: scale(1.05);
}

.trainer-next-tip-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Adjust footer padding (remove extra top padding since Next Tip is inline) */
.trainer-no-steps-chat-footer,
.trainer-no-image-chat-footer,
.trainer-chat-footer {
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}

/* Ensure action bar left has proper spacing */
.trainer-action-bar-left {
  display: flex;
  gap: 10px;
  align-items: center;
}